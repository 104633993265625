<template>
    <div class="hover:animate-zoomIn" @mouseover="hoverIcon = true"
        @mouseout="hoverIcon = false">
        <div class="border border-white rounded-full  mx-auto"
            :class="{ 'ring-2 ring-red-500': hoverIcon }">
                <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const hoverIcon = ref(false)
</script>
