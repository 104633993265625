<script setup>
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import store from '@/store'
import { loadIcon } from '@/helpers/loadIcon'
import AnimatedIcon from '@/components/AnimatedIcon.vue'
import Gallery from './Gallery.vue'
import PostSignature from './PostSignature.vue'
// import { renderRichText } from '@storyblok/vue'

// defineProps({ blok: Object })

const postId = useRoute().params?.id
let thisPost = {}

// const articleContent = computed(() => renderRichText(blok.articleContent))

const postsFromStore = computed(() => {
  return store.getters.getPostsFromStore
})

postsFromStore.value.forEach((post) => {
  // eslint-disable-next-line eqeqeq
  if (post.postTitle.replace(/\s+/g, '').toLowerCase() == postId) {
    thisPost = post
  }
})

const galleryImages = [
  {
    url: thisPost?.postMainImage?.filename,
    id: thisPost?.postMainImage?.id
  }
]

thisPost.postDescription.forEach(element => {
  if (element.image) {
    galleryImages.push({ url: element?.image?.filename, id: element?.image?.id })
  }
})

const currentImageIndex = ref(0)
const isShowGallery = ref(false)

// eslint-disable-next-line no-unused-vars
const showGallery = (idx) => {
  currentImageIndex.value = idx
  isShowGallery.value = true
}

const closeGallery = () => {
  isShowGallery.value = false
}

const getImageIndex = (id) => {
  return galleryImages.indexOf(galleryImages.filter(obj => { return obj?.id === id })[0])
}
</script>

<template>
  <Gallery v-if="isShowGallery" :image-links="galleryImages" :show-gallery="isShowGallery"
    :current-img="currentImageIndex" @close-gallery="closeGallery" @keydown.esc="closeGallery" tabindex="0" autofocus />
  <section class="mb-32">
    <div class="relative lg:mb-16">
      <div class="absolute inset-0 pointer-events-none mb-16" aria-hidden="true"></div>
      <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div class="pt-16">
          <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 class="text-3xl sm:text-5xl font-extrabold mb-4">{{ thisPost?.postTitle }}</h1>
            <p class="text-md sm:text-xl text-gray-600">{{ thisPost?.postTitleDescription }}</p>
          </div>
          <div class="flex flex-col lg:flex-row-reverse">
            <div class="rounded-md mb-8 lg:mb-0 w-full lg:w-1/2 lg:ml-4 overflow-hidden relative max-w-md mx-auto">
              <img class="w-full h-full object-cover object-center cursor-pointer" :key="thisPost?.postMainImage?.id"
                :src="thisPost?.postMainImage?.filename" :alt="thisPost?.postMainImage?.alt"
                @click="showGallery(0)">
            </div>
            <div class="lg:mt-6 px-0 w-full lg:w-1/2 max-w-[400px] mx-auto">
              <div class="flex flex-wrap content-between h-full">
                <div class="w-full">
                  <div class="mb-8">
                    <h3 class="text-xl sm:text-3xl font-bold mb-3">{{ thisPost?.postSubtitle }}</h3>
                    <p class="text-md sm:text-xl text-gray-600">{{ thisPost?.postSubtitleDescription }}</p>
                  </div>
                  <ul class="mb-8 lg:mb-0">
                    <li v-for="item in thisPost?.postFeatures" :key="item.title"
                      class="flex items-center justify-between text-base sm:text-lg p-4 rounded border transition duration-300 ease-in-out mb-3 bg-gray-200 border-transparent px-8">
                      <div class="pr-4">
                        <div class="font-bold leading-snug tracking-tight text-left">
                          {{ item.title }}
                        </div>
                        <div v-if="item.description" class="text-gray-600 text-left mt-1">
                          {{ item.description }}
                        </div>
                      </div>
                      <AnimatedIcon class="ml-3">
                        <div class="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0">
                          <component :is="loadIcon(item.icon)" class="w-5 h-5" />
                        </div>
                      </AnimatedIcon>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PostSignature :project-name="thisPost.generalProjectsName" :post-author="thisPost.postAuthor" class="p-6"/>
    <div class="p-8 pt-20 mt-12 max-w-6xl mx-auto flex flex-wrap text-justify flex-col md:flex-row border-t border-gray-100">
      <div v-for="(item, idx) in thisPost.postDescription" :key="`description_item_${idx}`"
        class="p-4 mt-auto mb-auto" :class="{
          'w-full md:w-1/4': item.width == 'quarter',
          'w-full md:w-1/2': item.width == 'half',
          'md:w-full mx-auto': item.width == 'full',
        }">
        <div v-if="item.component == 'text'"
          class="w-fit"
          :class="{
            'mx-auto': item.justify == 'center',
            'ml-0': item.justify == 'left',
            'mr-0': item.justify == 'right',
            }">
          {{ item.text }}
        </div>
        <div v-if="item.component == 'image'" class="h-full">
          <div class="relative inline-flex flex-col w-full">
            <img :key="item.image.id" :src="item.image.filename" :alt="item.image.alt"
              class="md:max-w-none mx-auto rounded w-full cursor-pointer"
              @click="showGallery(getImageIndex(item.image.id))">
            <p v-if="item.image.title" class="pt-2">{{ item.image.title }}</p>
          </div>
        </div>
        <div v-if="item.component == 'textWithImage'">
          <img class="rounded m-4 w-full lg:w-1/2 max-w-[600px] md:max-w-[400px] mx-auto cursor-pointer"
            :key="item.image.id" :class="{
              'md:float-left md:mx-4': item.imageSide == 'left',
              'md:float-right md:mx-4': item.imageSide == 'right'
            }" :src="item.image.filename" :alt="item.image.alt"
            @click="showGallery(getImageIndex(item.image.id))">
          <p v-if="item.image.title" class="pt-2">{{ item.image.title }}</p>
          {{ item.text }}
        </div>
      </div>
    </div>
  </section>
</template>
