import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/app.css'
import Page from '@/components/Page.vue'
import Project from '@/components/Project'
import Feature from '@/components/Feature.vue'
import PostPage from '@/components/PostPage.vue'
import { StoryblokVue, apiPlugin } from '@storyblok/vue'

createApp(App).use(store).use(router).use(StoryblokVue, {
  accessToken: process.env.VUE_APP_TOKEN,
  bridge: process.env.NODE_ENV !== 'production', // optimizes by excluding the bridge on production
  use: [apiPlugin]
})
  .component('Page', Page)
  .component('Project', Project)
  .component('Feature', Feature)
  .component('PostPage', PostPage)
  .mount('#app')
