<script setup>
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import LogoIcon from './icons/LogoIcon.vue'
import MenuIcon from './icons/MenuIcon.vue'

const isMenu = ref(false)

function toggleMenu () {
  isMenu.value = !isMenu.value
}

const routesForNav = []
const wantedRoutes = ['Home', 'Electronics', 'Models', 'Others', 'About']
useRouter().options.routes.forEach((route) => {
  if (wantedRoutes.some((name) => name === route.name)) {
    routesForNav.push(route)
  }
})

const route = useRoute()

watch(
  () => route.name, () => { isMenu.value = false }
)
</script>

<template>
    <header class="w-full z-10 my-4 max-w-7xl mx-auto">
            <div class="flex items-center justify-between h-full relative bg-gray-50 shadow-default px-12 py-6 rounded-2xl">
                <div class="mr-4">
                    <LogoIcon class="w-10 h-10 animate-tada hover:animate-tadaFast" />
                </div>
                <nav class="flex flex-grow h-full items-center">
                    <MenuIcon @click="toggleMenu" class="h-10 w-10 md:hidden absolute right-10 top-0 m-4 cursor-pointer font-bold text-xl capitalize"/>
                    <div v-if="isMenu" class="md:hidden absolute z-10 right-5 top-12 bg-gray-50 shadow-default shadow-lg shadow-default p-3 rounded-lg border-4 border-my- backdrop-blur">
                        <ul>
                            <li v-for="item in routesForNav" :key="item.name" class="px-4 py-1">
                            <router-link :to="item.path"
                                class="relative font-bold text-xl capitalize drop-shadow-lg shadow-my-yellow px-4 my-3 py-1 border-b-[2px] border-transparent
                                    hover:ease-in duration-150"
                                :class="{ '!text-my-red': $route.path == item.path }">
                                {{ item.name }}
                            </router-link>
                        </li>
                        </ul>
                    </div>
                    <ul class="hidden md:flex flex-grow justify-end flex-wrap items-end">
                        <li v-for="item in routesForNav" :key="item.name" class="px-2">
                            <router-link :to="item.path"
                                class="relative font-bold text-xl capitalize hover:text-gray-400 drop-shadow-lg shadow-my-yellow px-2 my-3 py-1 border-b-[2px] border-transparent
                                    hover:ease-in duration-150"
                                :class="{ '!text-my-red': $route.path == item.path }">
                                {{ item.name }}
                            </router-link>
                        </li>
                    </ul>
                </nav>
            </div>
    </header>
</template>

<style scoped>
.under::before {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0%;
    height: 2px;
    /* background: rgb(156, 0, 175) */
    /* background: #da4a54; */
    /* background: #2c3e50; */
    /* background: linear-gradient(90deg, #DA4A54,#F6E266); */
}
</style>
