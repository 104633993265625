import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/models',
    name: 'Models',
    component: () => import('../views/ModelsView.vue')
  },
  {
    path: '/electronics',
    name: 'Electronics',
    component: () => import('../views/ElectronicsView.vue')
  },
  {
    path: '/others',
    name: 'Others',
    component: () => import('../views/OthersView.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/post/:id?',
    name: 'Post',
    component: () => import('../views/PostView.vue')
  },
  {
    path: '/projectlist/:name?',
    name: 'ProjectList',
    component: () => import('../views/ProjectListView.vue')
  },
  {
    path: '/authorlist/:author?',
    name: 'AuthorList',
    component: () => import('../views/AuthorListView.vue')
  },
  {
    path: '/privacypolicy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
