<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps({
  projectName: {
    type: String,
    default: undefined
  },
  postAuthor: {
    type: String,
    default: undefined
  }
})

const isSignature = ref(props.postAuthor || props.projectName)
const doubleSinature = ref(props.postAuthor?.length > 0 && props.projectName?.length > 0)

const showProjectTooltip = ref(false)
const showAuthorTooltip = ref(false)
</script>

<template>
  <div v-if="isSignature" class="mt-8 mx-auto flex text-xs sm:text-sm md:text-base">
    <div v-if="postAuthor" class="w-1/2 only:w-full relative">
      <p class="w-fit ml-auto mr-6 cursor-default" :class="{ '!mx-auto': !projectName }"
        @mouseover="showAuthorTooltip = true">
        Author: {{ postAuthor }}
        <Transition>
          <router-link v-if="showAuthorTooltip"
            :to="{ name: 'AuthorList', params: { author: postAuthor } }" exact>
            <div class="absolute -top-2 right-6 z-50 py-2 px-4 bg-gray-50 shadow-default rounded"
              :class="{ 'right-1/2 translate-x-1/2': !projectName }"
              @mouseout="showAuthorTooltip = false">
              See all posts for this author
            </div>
          </router-link>
        </Transition>
      </p>
    </div>
    <span v-if="doubleSinature">|</span>
    <div v-if="projectName" class="w-1/2 only:w-full relative">
      <div class="w-fit mr-auto ml-6" :class="{ '!mx-auto': !postAuthor }"
        @mouseover="showProjectTooltip = true">
        Project: {{ projectName }}
        <Transition>
          <router-link v-if="showProjectTooltip" :to="{ name: 'ProjectList', params: { name: projectName } }" exact>
            <div class="absolute -top-2 z-50 py-2 px-4 bg-gray-50 shadow-default rounded"
              :class="{ 'right-1/2 translate-x-1/2': !postAuthor }"
              @mouseout="showProjectTooltip = false">
              See all posts for this project
            </div>
          </router-link>
        </Transition>
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
