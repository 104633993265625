<script setup>
import { computed } from 'vue'
import LogoIcon from './icons/LogoIcon.vue'
import { RouterLink, useRouter } from 'vue-router'
import store from '@/store'

const routesForNav = []
const resourcesRoutes = ['Home', 'Electronics', 'Models', 'Others']
useRouter().options.routes.forEach((route) => {
  if (resourcesRoutes.some((name) => name === route.name)) {
    routesForNav.push(route)
  }
})

const projectList = computed(() => {
  return store.getters.getProjectList
})

const columns = computed(() => {
  return [
    {
      name: 'Resources',
      list: routesForNav
    },
    {
      name: 'Last projects',
      list: [{
        name: projectList?.value[0],
        path: `/projectlist/${projectList?.value[0]}`
      },
      {
        name: projectList?.value[1],
        path: `/projectlist/${projectList?.value[1]}`
      },
      {
        name: projectList?.value[2],
        path: `/projectlist/${projectList?.value[2]}`
      },
      {
        name: projectList?.value[3],
        path: `/projectlist/${projectList?.value[3]}`
      }]
    },
    {
      name: 'About me',
      list: [
        {
          name: 'About page',
          path: '/about'
        },
        {
          name: 'Contact',
          path: '/about#contact-section'
        },
        {
          name: 'Privacy Policy',
          path: '/privacypolicy'
        }
      ]
    }
  ]
})

</script>

<template>
  <footer>
    <div
      class="relative max-w-7xl mx-auto py-6 px-6 md:px-12 bg-gray-50 text-gray-600 shadow-default rounded-2xl mb-4 shadow-default">
      <div class="flex flex-col sm:flex-row justify-between gap-8 md:gap-24 py-8 md:py-6 border-gray-200">
        <div class="w-1/4 mx-auto mt-6">
          <div class="mb-2">
            <a class="inline-block" aria-label="Gepetto" href="">
              <LogoIcon class="w-12 h-12 animate-tada hover:animate-tadaFast" />
            </a>
          </div>
          <div class="text-sm">
            <a class="hover:underline transition duration-150 ease-in-out hover:text-gray-400" href="privacypolicy">
              Privacy Policy
            </a>
          </div>
        </div>
        <div class="flex w-3/4 md:w-1/2 justify-around mx-auto flex-wrap gap-4">
          <div v-for="(column, idx) in columns" :key="`column_${idx}`" class="text-left mt-4">
            <h6 class="font-semibold mb-2">{{ column.name }}</h6>
            <ul class="text-sm">
              <li v-for="(item, idList) in column.list" :key="`item_${idx}${idList}`" class="mb-2">
                <RouterLink :to="item.path" exact class="transition duration-150 ease-in-out hover:text-gray-400">
                  {{ item.name }}
                </RouterLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
        <ul class="flex mb-4 md:order-1 md:ml-4 md:mb-0">
          <li>
            <a class="flex justify-center items-center text-my-gray hover:text-gray-300 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
              aria-label="Twitter" href="">
              <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path
                  d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z">
                </path>
              </svg>
            </a>
          </li>
          <li class="ml-4">
            <a class="flex justify-center items-center text-my-gray hover:text-gray-300 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
              aria-label="Github" href="">
              <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path
                  d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z">
                </path>
              </svg>
            </a>
          </li>
          <li class="ml-4">
            <a class="flex justify-center items-center text-my-gray hover:text-gray-300 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
              aria-label="Facebook" href="">
              <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path
                  d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z">
                </path>
              </svg>
            </a>
          </li>
        </ul>
        <div class="text-md mr-4">
          Made by
          <a class="hover:text-black font-bold" href="">
            Gepetto
          </a>
          . All rights reserved.
        </div>
      </div>
    </div>
  </footer>
</template>
