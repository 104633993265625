/* eslint-disable no-unused-vars */
import { createStore } from 'vuex'
import { defineAsyncComponent, markRaw } from 'vue'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state () {
    return {
      postsFromStoryblok: [],
      projectList: []
    }
  },
  getters: {
    getPostsFromStore (state) {
      return state.postsFromStoryblok
    },
    getProjectList (state) {
      return state.projectList
    }
  },
  mutations: {
    loadPosts (state, gotPosts) {
      state.postsFromStoryblok = gotPosts
      gotPosts.map((posts) => {
        if (!state.projectList.includes(posts.generalProjectsName) && posts.generalProjectsName.length) {
          state.projectList.push(posts.generalProjectsName)
        }
        return null
      })
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})
