<!-- eslint-disable eqeqeq -->
<script>
import IconClose from './icons/IconClose.vue'
import IconArrowLeft from './icons/IconArrowLeft.vue'
import IconArrowRight from './icons/IconArrowRight.vue'

export default {
  props: {
    imageLinks: {
      type: Array,
      requred: true
    },
    showGallery: {
      type: Boolean,
      default: false
    },
    currentImg: {
      type: Number,
      default: 0
    }
  },
  components: {
    IconClose,
    IconArrowLeft,
    IconArrowRight
  },
  data () {
    return {
      currentIndex: this.currentImg,
      show: this.showGallery
    }
  },

  computed: {
    currentImage () {
      return this.imageLinks[this.currentIndex]
    }
  },
  mounted () {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.closeGallery()
      }

      if (e.key === 'ArrowLeft') {
        this.prevImage()
      }

      if (e.key === 'ArrowRight') {
        this.nextImage()
      }
    })
  },
  methods: {
    prevImage () {
      if (this.currentIndex > 0) this.currentIndex--
      else this.currentIndex = this.imageLinks.length - 1
    },
    nextImage () {
      if (this.currentIndex == this.imageLinks.length - 1) this.currentIndex = 0
      else this.currentIndex++
    },
    closeGallery () {
      this.show = false
      this.$emit('closeGallery')
      this.props.showGallery = 0
    }
  },

  watch: {
    showGallery: {
      immediate: true,
      handler (newVal, oldVal) {
        this.show = newVal
      }
    },
    currentImg: {
      immediate: true,
      handler (newVal, oldVal) {
        this.currentIndex = newVal
      }
    }
  }
}

</script>

<template>
    <template v-if="show">
        <div class="gallery-container relative">
            <div class="current-img text-xl"> {{ currentIndex + 1 }} z {{ imageLinks.length }}</div>
            <div class="flex justify-end">
                <div @click="closeGallery" class="icon-container p-2 mr-4 mt-2">
                    <IconClose class="icon-close" height="20" width="20" />
                </div>
            </div>
            <div class="gallery">
                <div @click="prevImage" class="arrow arrow-left">
                    <div class="icon-container flex items-center h-full">
                        <IconArrowLeft class="previous p-2 rounded-full bg-gray-200 w-9 h-9 opacity-70" color="black" />
                    </div>
                </div>
                <div class="h-full">
                    <img :src="currentImage.url" alt="" class="h-auto ">
                </div>
                <div @click="nextImage" class="arrow arrow-right">
                    <div class="icon-container flex items-center h-full">
                        <IconArrowRight class="next p-2 rounded-full bg-gray-200 w-9 h-9 opacity-70" color="black" />
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<style scoped>
.gallery-container {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 95%);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    color: #c3c3c3;
}

.gallery {
    width: 100%;
    height: 100%;
    position: relative;
}

.gallery img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    max-height: 80%;
    max-width: 80%;
}
.current-img {
    position: absolute;
    left: 50%;
    margin-top: 1rem;
}

.gallery .arrow {
    position: absolute;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding: 1rem;
    z-index: 100;
}

.gallery .arrow-left {
    left: 0.5rem;
}

.gallery .arrow-right {
    right: 0.5rem;
}

.gallery .align-right {
    text-align: right;
}

.image .icon-close {
    width: 1rem;
    height: 1rem;
}

.icon-container {
    cursor: pointer;
    fill: #696969;
}

.icon-container:hover {
    fill: #ccc;
}
</style>
